import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import ModalTestimonials from "../model/ModalTestimonials";

import ClientOneImg from "../../assets/images/client1.jpg";
import ClientTwoImg from "../../assets/images/clienttwo.jpg";
import ClientThreeImg from "../../assets/images/clientthree.jpg";

const testimonialData = [
  { name: "Stefan Kremic", brand: "Canada", img: ClientOneImg },
  { name: "Angela Jane", brand: "Australia", img: ClientTwoImg },
  { name: "Dennis Mccoy", brand: "USA", img: ClientThreeImg },
];

function Testimonial() {
  const [number, setNumber] = useState();
  const Navigate = useNavigate();

  const handleTestimonialClick = (num) => {
    setNumber(num);
  };

  const settings = {
    prevArrow: (
      <button type="button" className="slick-prev">
        Previous
      </button>
    ),
    nextArrow: (
      <button type="button" className="slick-next">
        Next
      </button>
    ),
    autoplaySpeed: 3000,
    dots: false,
    arrows: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 499,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>
      <section className="testi">
        <div
          className="back-orange-art testi-orange1"
          data-aos="fade-right"
          data-aos-duration="800"
          data-aos-delay="300"
          data-aos-offset="0"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="252.883"
            height="123.877"
            viewBox="0 0 252.883 123.877"
          >
            <g
              id="Group_68"
              data-name="Group 68"
              transform="translate(-4187.117 -707.061)"
            >
              <rect
                id="Rectangle_99"
                data-name="Rectangle 99"
                width="123.877"
                height="252.883"
                rx="61.939"
                transform="translate(4440 707.061) rotate(90)"
                fill="#f38020"
                opacity="0.05"
              />
              <rect
                id="Rectangle_100"
                data-name="Rectangle 100"
                width="74.596"
                height="202.142"
                rx="37.298"
                transform="translate(4414.629 731.702) rotate(90)"
                fill="#f38020"
                opacity="0.05"
              />
              <rect
                id="Rectangle_101"
                data-name="Rectangle 101"
                width="35.472"
                height="150.653"
                rx="17.736"
                transform="translate(4388.885 751.264) rotate(90)"
                fill="#f38020"
                opacity="0.05"
              />
              <rect
                id="Rectangle_102"
                data-name="Rectangle 102"
                width="10.297"
                height="111.304"
                rx="5.148"
                transform="translate(4369.21 763.851) rotate(90)"
                fill="#f38020"
                opacity="0.05"
              />
            </g>
          </svg>
        </div>
        <div
          className="back-orange-art testi-orange2"
          data-aos="fade-left"
          data-aos-duration="800"
          data-aos-delay="300"
          data-aos-offset="0"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="252.883"
            height="123.877"
            viewBox="0 0 252.883 123.877"
          >
            <g
              id="Group_68"
              data-name="Group 68"
              transform="translate(-4187.117 -707.061)"
            >
              <rect
                id="Rectangle_99"
                data-name="Rectangle 99"
                width="123.877"
                height="252.883"
                rx="61.939"
                transform="translate(4440 707.061) rotate(90)"
                fill="#f38020"
                opacity="0.05"
              />
              <rect
                id="Rectangle_100"
                data-name="Rectangle 100"
                width="74.596"
                height="202.142"
                rx="37.298"
                transform="translate(4414.629 731.702) rotate(90)"
                fill="#f38020"
                opacity="0.05"
              />
              <rect
                id="Rectangle_101"
                data-name="Rectangle 101"
                width="35.472"
                height="150.653"
                rx="17.736"
                transform="translate(4388.885 751.264) rotate(90)"
                fill="#f38020"
                opacity="0.05"
              />
              <rect
                id="Rectangle_102"
                data-name="Rectangle 102"
                width="10.297"
                height="111.304"
                rx="5.148"
                transform="translate(4369.21 763.851) rotate(90)"
                fill="#f38020"
                opacity="0.05"
              />
            </g>
          </svg>
        </div>

        <div className="testi-inner container">
          <div className="sec-heading">
            <div
              className="sap-caption-head"
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="800"
              data-aos-offset="0"
            >
              <div className="sap-caption">Who got noticed</div>
            </div>
            <div
              className="sap-lg-heading"
              data-aos="fade-down"
              data-aos-duration="800"
              data-aos-delay="300"
              data-aos-offset="0"
            >
              Testimonials
            </div>
          </div>
          <Slider {...settings} className="testimonials owl-carousel owl-theme">
            {testimonialData.map((testimonial, index) => (
              <div key={index} className="item">
                <button
                  className="testi-thumb"
                  data-bs-toggle="modal"
                  data-bs-target="#testi-video-pop"
                  onClick={() => handleTestimonialClick(index + 1)}
                >
                  <img src={testimonial.img} alt={`Client ${index + 1}`} />
                  <span className="play-btn">
                    <svg
                      onClick={() => handleTestimonialClick(index + 1)}
                      viewBox="0 0 65 65"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g
                        transform="translate(-385 -4473)"
                        fill="none"
                        data-name="Group 40"
                      >
                        <g
                          transform="translate(385 4473)"
                          stroke="#fff"
                          strokeWidth="2"
                          data-name="Ellipse 3"
                        >
                          <circle cx="32.5" cy="32.5" r="32.5" stroke="none" />
                          <circle cx="32.5" cy="32.5" r="31.5" fill="none" />
                        </g>
                        <g
                          transform="translate(434.13 4491.1) rotate(90)"
                          data-name="Polygon 1"
                        >
                          <path d="M11.295,5.828a4,4,0,0,1,6.887,0l7.729,13.079a4,4,0,0,1-3.444,6.035H7.01a4,4,0,0,1-3.444-6.035Z" />
                          <path
                            d="m14.738 5.8627c-0.34315 0-1.1978 0.095741-1.7219 0.98254l-7.7285 13.079c-0.53329 0.90249-0.1883 1.7054-0.015228 2.0087 0.17308 0.30331 0.68881 1.0088 1.7371 1.0088h15.457c1.0483 0 1.564-0.70546 1.7371-1.0088 0.17307-0.30331 0.51806-1.1062-0.015232-2.0087l-7.7285-13.079c-0.52402-0.8868-1.3787-0.98254-1.7219-0.98254m0-2c1.3348 0 2.6696 0.65503 3.4437 1.9651l7.7285 13.079c1.5756 2.6665-0.34651 6.0349-3.4437 6.0349h-15.457c-3.0972 0-5.0194-3.3685-3.4437-6.0349l7.7285-13.079c0.77413-1.3101 2.1089-1.9651 3.4437-1.9651z"
                            fill="#fff"
                          />
                        </g>
                      </g>
                    </svg>
                  </span>
                </button>
                <div className="testi-con">
                  <div className="testi-bot-con">
                    <div className="testi-name">{testimonial.name}</div>
                    <div className="testi-brand">{testimonial.brand}</div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>

          <div
            className="sap-btn-light"
            data-aos="fade-up"
            data-aos-duration="800"
            data-aos-delay="800"
            data-aos-offset="0"
          >
            <button type="button" onClick={() => Navigate("/testimonials")}>
              view more
            </button>
          </div>
        </div>
      </section>
      {number && (
        <ModalTestimonials
          modalTestimonials={true}
          setModalTestimonials={setNumber}
          number={number}
          setNumber={setNumber}
        />
      )}
    </>
  );
}
export default Testimonial;
