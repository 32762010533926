import 'matchmedia-polyfill';
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from "react-toastify";
import App from "./App";
// import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./Redux/Store";
import { AppProvider } from "./context/AppContext";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AppProvider>
    <Provider store={store}>
      <BrowserRouter>
      <App />
      <ToastContainer theme="dark" position="top-right" />
      </BrowserRouter>
    </Provider>
  </AppProvider>
);
// reportWebVitals();