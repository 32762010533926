import { Fragment, useEffect } from "react";
import Services from "../Services/Services";
import HomeFormLight from "./HomeFormLight";
import Homeblog from "../Blogs/Homeblog";
import Testimonial from "./Testimonial";
import HowWeWork from "./HowWeWork";
import Techwork from "./Techwork";
import Deals from "./Deals";
import Hero from "./Hero";
// import { MetaTags } from "react-meta-tags";
import "../../scss/homepage2.scss";

const HomePage2 = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "instant",
    });
  }, []);
  
  return (
    <Fragment>
{/* <MetaTags>
  <meta
    id="meta-description"
    name="description"
    content="Kindlebit Solutions is a top IT service provider. We offer web & mobile app development, digital marketing, and other services. Contact us now!"
  />
</MetaTags> */}
      <div className="home-light-theme">
        <Hero />
        <Services />
        <Techwork />
        <HowWeWork />
        <Testimonial />
        <Deals />
        <HomeFormLight />
        <Homeblog />
      </div>
    </Fragment>
  );
};
export default HomePage2;
